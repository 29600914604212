import React from 'react'
import { Header } from './pages/Header.js'
import { History } from './pages/History.js'
import { ProductSection } from './pages/ProductSection.js'
import { SeasonalProduct } from './pages/SeasonalProduct.js'
import { Contact } from './pages/Contact.js'
import { Testimonials } from './pages/Testimonials.js'

export const Landing = () => {
  return (
    <>
        <Header />
        <History />
        <ProductSection />
        <SeasonalProduct />
        <Testimonials />
        <Contact />
    </>
  )
}
