import React, { useEffect, useState } from 'react'
import '../../css/pages/Testimonials.css'
import { Star } from '../visualComponent/Star'
import TestimonialsData from '../../assets/staticData/Testimonials.json'
import chef from '../../assets/images/testimonials/chef.webp'
import testimonialPic from '../../assets/images/testimonials/testimonial.webp'
import { useTranslation } from 'react-i18next';

export const Testimonials = () => {

    const { t } = useTranslation("global");

    const [testimonial, setTestimonial] = useState(null);
    const [position, setPosition] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-enter');
    const pictures = {
        "chef": chef,
        "testimonials": testimonialPic,
    }
    


    useEffect(() => {
        const filterData = TestimonialsData['testimonial'].filter(item => item.position === position)[0];
        setFadeClass('fade-exit');

        const timeout = setTimeout(() => {
            setFadeClass('fade-enter');
            setTestimonial(filterData);
        }, 300);

        return () => clearTimeout(timeout);
    }, [position])

    const setStarsNumber = () => {
        return Array.from({ length: testimonial.stars }).map((_, index) => <Star key={index} />);
    }

    const setNewPosition = (newPosition) => {
        if (newPosition >= 0 && newPosition < TestimonialsData['testimonial'].length) {
            setPosition(newPosition);
        }
    }

    return (
        <>
            <section id="testimonials-section" className='testimonials-section'>
                <div className='testimonials-testimonial-comment'>
                    <div className='testimonial-comment-picture'>
                        <img src={testimonial && pictures[testimonial.image]} alt={testimonial && testimonial.name} />
                    </div>
                    <h1 className={`testimonial-comment-title ${fadeClass}`}>
                        {testimonial && testimonial.name}
                    </h1>
                    <h3 className='testimonial-comment-subtitle'>
                        {t('testimonials.advise')}:
                    </h3>
                    <div className='testimonial-comment-number'></div>
                    <p className={`testimonials-comment-description ${fadeClass}`}>
                        {testimonial && t(`testimonials.${testimonial.nameTranslation}.testimonial`)}
                    </p>
                    <div className='testimonials-comment-arrows'>
                        <span className='testimonials-comment-arrows-left' onClick={() => setNewPosition(position - 1)}></span>
                        <span className='testimonials-comment-arrows-right' onClick={() => setNewPosition(position + 1)}></span>
                    </div>
                </div>
                <div className='testimonials-testimonial-recipe'>
                    <div className='testimonials-recipe-picture'>
                    </div>
                    <div className='testimonials-recipe-comment'>
                        <div className={`testimonials-recipe-comment-star ${fadeClass}`}>
                            {testimonial && setStarsNumber()}
                        </div>
                        <p className={`${fadeClass}`}>
                            {testimonial && t(`testimonials.${testimonial.nameTranslation}.tip`)}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}
