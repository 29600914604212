import React from 'react'
import { useTranslation } from 'react-i18next';
import '../../css/pages/SeasonalProduct.css'

export const SeasonalProduct = () => {
    const { t } = useTranslation("global");
    return (
        <>
            <section id="seasonal-product" className='seasonal-product'>
                <div className='seasonal-product-text'>
                    <h1>
                        {t('seasonal_product.title')}
                    </h1>
                    <h3>
                        {t('seasonal_product.subtitle')}
                    </h3>
                    <p>
                        {t('seasonal_product.description')}
                    </p>
                </div>
            </section>
        </>
    )
}
