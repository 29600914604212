import React from 'react'
import EN from '../../assets/images/flags/en.webp';
import FI from '../../assets/images/flags/fi.webp';
import FR from '../../assets/images/flags/fr.webp';
import SW from '../../assets/images/flags/sw.webp';
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../services/LanguageContextProvider.js';


export const LanguageDropdown = () => {

    const { i18n } = useTranslation("global");

    const { language, setNewLanguage } = useLanguage();

    const languages = {
        'EN': {
            flag: EN,
            languageName: 'English'
        },
        'FI': {
            flag: FI,
            languageName: 'Suomi'
        }, 'SW': {
            flag: SW,
            languageName: 'Svenska'
        }, 'FR': {
            flag: FR,
            languageName: 'Français'
        }
    }

    const handleOptionChange = (event) => {
        const language = event.target.value;
        setNewLanguage(language);
        i18n.changeLanguage(language);
    };

    return (
        <div className='dropdown-container'>
            <img src={languages[language].flag} className='dropdown-flag navbar-buttons-section' alt={`${languages[language].languageName} flag`} />
            <select className='dropdown-select' id="language" value={language} onChange={(event) => handleOptionChange(event)}>
                {Object.keys(languages).map((key) => (
                    <option className='dropdown-select-option' key={key} value={key}>{languages[key].languageName}</option>
                ))}
            </select>
        </div>
    )
}
