import { LanguageContextProvider } from './services/LanguageContextProvider';
import './App.css';
import { Router } from './router/Router';

function App() {
  return (
    <div className="App">
      <LanguageContextProvider>
        <Router />
      </LanguageContextProvider>
    </div>
  );
}

export default App;
