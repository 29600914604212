import React from 'react'
import '../../css/pages/ProductSection.css'
import { ContactButton } from '../visualComponent/ContactButton'
import { useTranslation } from 'react-i18next';
import { Product } from '../visualComponent/Product';

export const ProductSection = () => {
    const { t } = useTranslation("global");
    return (
        <>
            <section id="product-section" className="product-section">
                <div className='product-title-container'>
                    <div>
                        <h3 className='product-section-title'>{t('product_section.title')}</h3>
                        <p className='product-section-description'>{t('product_section.description')}</p>
                    </div>
                    <ContactButton buttonText={t('header.button.order')} cssClass={'header-text-button button-products'} sectionId={"contact-section"}/>
                </div>
                <div>
                    <Product
                        cssClass={'product-image-container'}
                        imageName={'packages1'}
                        title={t('product_section.packages1.title')}
                        description={t('product_section.packages1.description')} />
                    <Product
                        cssClass={'product-image-container'}
                        imageName={'packages2'}
                        title={t('product_section.packages2.title')}
                        description={t('product_section.packages2.description')}
                    />
                    <Product
                        cssClass={'product-image-container'}
                        imageName={'packages3'}
                        title={t('product_section.packages3.title')}
                        description={t('product_section.packages3.description')}
                    />
                </div>
            </section>
        </>
    )
}
