import React from 'react'
import '../../css/visualComponent/Product.css'

export const Product = ({cssClass, imageName, title, description}) => {

    return (
        <>
            <div className={`${cssClass} ${imageName}`}>
                <div className={`product-image product-image-${imageName}`}></div>
                <div className='product-image-text'>
                    <h1>{title}</h1>
                    <p>{description}</p>
                </div>
            </div>
        </>
    )
}
