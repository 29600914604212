import React from 'react'
import '../../css/pages/Contact.css'
import { useTranslation } from 'react-i18next';
import { ContactForm } from '../visualComponent/ContactForm';

export const Contact = () => {
    const telephoneNumber = " +358 40 563 2912"
    const minAmount = 0; //Min amount seems to be no longer needed

    const { t } = useTranslation("global");
    return (
        <>
            <section id='contact-section' className='contact-section'>
                <div className='contact-section-text'>
                    <h1 className='contact-section-text-title'>{t('contact_form.title')}</h1>
                    <p className='contact-section-text-description'>
                        {t('contact_form.description')}
                        <a href="tel:+358 40 563 2912" className='contact-section-text-phone'>
                            {telephoneNumber}
                        </a>
                    </p>
                    <p className='contact-section-text-description-bottom'>
                        {t('contact_form.description_bottom', { minAmount: minAmount })}
                    </p>
                    <div className='contact-section-text-picture'></div>
                </div>
                <ContactForm minAmount={minAmount}/>
            </section>
        </>
    )
}
