import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import global_en from './i18n/en/global.json'
import global_es from './i18n/es/global.json'
import global_fi from './i18n/fi/global.json'
import global_sw from './i18n/sw/global.json'
import global_fr from './i18n/fr/global.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('language') || "EN",
  resources: {
    ES: {
      global: global_es
    },
    FI: {
      global: global_fi
    },
    SW: {
      global: global_sw
    },
    FR: {
      global: global_fr
    },
    EN: {
      global: global_en
    }
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
