import React, { useRef, useState } from 'react'
import '../../css/visualComponent/ContactForm.css'
import { useTranslation } from 'react-i18next';
import { ContactButton } from './ContactButton';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

export const ContactForm = ({minAmount}) => {
    const { t } = useTranslation("global");
    const { register, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const form = useRef();
    const [okMessage, setOkMessage] = useState(false);

    // TODO submit disabled is set to false until we implement captcha validation
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const emailProjectName = process.env.REACT_APP_EMAIL_SERVICE_ID
    const emailLayout = process.env.REACT_APP_EMAIL_LAYOUT
    const emailProjectKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY


    //TODO add captcha
    // const validateSubmit = () => {
    //     return submitDisabled ? false : true;
    // }

    // const validateCaptcha = (value) => {
    //     value === undefined ? setSubmitDisabled(true) : setSubmitDisabled(false);
    // }

    const handleFormData = (data) => {
        if (submitDisabled) {
            return;
        }

        emailjs.sendForm(emailProjectName, emailLayout, form.current, emailProjectKey)
            .then((result) => {
                console.log(`Sending email result: ${result.text}`);
                form.current.reset();
                setOkMessage(true);
                clearErrors();
            }, (error) => {
                console.log(`Seding email Error: ${error.text}`);
            });
    }
    return (
        <>
            <form ref={form} className='contact-section-form' onSubmit={handleSubmit(handleFormData)}>
                <div className='contact-section-form-first-part'>
                    <input type="text"
                        id="customerName"
                        name="customerName"
                        {...register('customerName', {
                            required: true,
                            minLength: 3
                        })}
                        placeholder={t('contact_form.form.customerName')} />
                    {errors.customerName?.type && <p className='contat-form-error' >{t(`contact_form.form.customerNameError.${errors.customerName?.type}`)}</p>}
                    <input type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        {...register('phoneNumber', {
                            required: true,
                            minLength: 3
                        })}
                        placeholder={t('contact_form.form.phone')} />
                    {errors.phoneNumber?.type && <p className='contat-form-error' >{t(`contact_form.form.phoneNumberError.${errors.phoneNumber?.type}`)}</p>}
                    <input type="text"
                        id="company"
                        name="company"
                        {...register('company', {
                            required: true,
                            minLength: 3
                        })}
                        placeholder={t('contact_form.form.company')} />
                    {errors.company?.type && <p className='contat-form-error' >{t(`contact_form.form.companyError.${errors.company?.type}`)}</p>}
                    <input type="email"
                        id="email"
                        name="email"
                        {...register('email', {
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            }
                        })}
                        placeholder={t('contact_form.form.email')} />
                    {errors.email?.type && <p className='contat-form-error' >{t(`contact_form.form.emailError.${errors.email?.type}`)}</p>}
                </div>
                <div className='contact-section-form-second-part'>
                    <input type="text"
                        id="address"
                        name="address"
                        {...register('address', {
                            required: true,
                            minLength: 3
                        })}
                        placeholder={t('contact_form.form.address')} />
                    {errors.address?.type && <p className='contat-form-error' >{t(`contact_form.form.addressError.${errors.address?.type}`)}</p>}
                    <input type="number"
                        id="quantity"
                        name="quantity"
                        onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                                e.target.value = 0;
                            }
                        }}
                        {...register('quantity', {
                            required: false
                        })}
                        placeholder={t('contact_form.form.quantity')} />
                    <input type="textarea"
                        id="message"
                        name="message"
                        {...register('message', {
                            required: true
                        })}
                        placeholder={t('contact_form.form.message')} />
                    {errors.message?.type && <p className='contat-form-error' >{t(`contact_form.form.messageError.${errors.message?.type}`)}</p>}
                    <ContactButton buttonText={t('header.button.order')} cssClass={'contact-section-form-second-part-button'} />
                    {okMessage && <p className='contat-form-email-received' >We will contact you as soon as possible. Thanks!</p>}
                </div>
            </form >
        </>
    )
}
