import React from 'react'
import { useTranslation } from 'react-i18next';
import '../../css/pages/Header.css';
import logo from '../../assets/images/logo.webp';
import facebook from '../../assets/images/social_logos/facebook.webp';
import instagram from '../../assets/images/social_logos/instagram.webp';
import { LanguageDropdown } from '../visualComponent/LanguageDropdown';
import { ContactButton } from '../visualComponent/ContactButton';

export const Header = () => {
    const { t } = useTranslation("global");

    return (
        <>
            <section className='header-container'>
                <div className='header-navbar-container'>
                    <div className='header-navbar-logo'>
                        <img src={logo} alt='logo' />
                        <div className='vertical-line first-vertical'></div>
                        <a href='https://www.facebook.com/abyfoods/'>
                            <img className='header-navbar-social' src={facebook} alt='Facebook profile link' />
                        </a>
                        <a href='https://instagram.com/abyfoods?igshid=MWZjMTM2ODFkZg==' >
                            <img className='header-navbar-social' src={instagram} alt='Instagram profile link' />
                        </a>
                        <div className='vertical-line'></div>
                    </div>
                    <div className='header-navbar-links'>
                        <ul>
                            <li>
                                <a href='#history-section'>
                                    {t('header.navbar.linkAby')}
                                </a>
                            </li>
                            <li>
                                <a href='#product-section'>
                                    {t('header.navbar.linkProducts')}
                                </a>
                            </li>
                            <li>
                                <a href='#contact-section'>
                                    {t('header.navbar.linkOrder')}
                                </a>
                            </li>
                            <li>
                                <LanguageDropdown />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='header-text'>
                    <h1 className='header-text-title'>{t('header.text.title')}</h1>
                    <h3 className='header-text-subtitle'>{t('header.text.subtitle')}</h3>
                    <div className='header-text-buttons'>
                        <ContactButton buttonText={t('header.button.products')} cssClass={'header-text-button'} sectionId={"product-section"}/>
                        <ContactButton buttonText={t('header.button.order')} cssClass={'header-text-button black-white'} sectionId={"contact-section"}/>
                    </div>
                </div>
            </section>
        </>
    )
}
