import React from 'react'
import '../../css/pages/History.css'
import { useTranslation } from 'react-i18next'
import { ContactButton } from '../visualComponent/ContactButton';

export const History = () => {
  const { t } = useTranslation("global");

  return (
    <>
      <section id='history-section' className='history-section'>
        <div className='history-section-container'>
          <div className='history-images-block'>
            <div className='history-images-large' ></div>
            <div className='history-images-second-block'>
              <div className='history-images-small'></div>
              <div className='history-images-medium'></div>
            </div>
          </div>
          <div className='history-text-block'>
            <h1 className='history-text-title'>{t('history.text.title')}</h1>
            <h3 className='history-text-subtitle'>{t('history.text.subtitle')}</h3>
            <p className='history-text-description'>{t('history.text.description')}</p>
            <ContactButton buttonText={t('header.button.products')} cssClass={'header-text-button'} sectionId={"product-section"}/>
          </div>
        </div>
        <div className='history-section-claim'>
          <h3 className='history-section-claim-title'>{t('history.claim.title')}</h3>
          <h3 className='history-section-claim-subtitle'>{t('history.claim.subtitle')}</h3>
        </div>
      </section>
    </>
  )
}
