import React from 'react'
import '../../css/visualComponent/ContactButton.css'

export const ContactButton = ({ buttonText, cssClass, sectionId }) => {
    return (
        <>
            <button className={`${cssClass} custom-button`} onClick={() => {
                const contactSection = document.getElementById(sectionId);
                if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                }
            }
            }>{buttonText}</button>
        </>
    )
}
